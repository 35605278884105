<template>
  <v-container align="center" justify="center">
    <v-row class="mx-auto py-4">
      <v-col cols="12">
        <h1>
          <router-link :to="{ name: 'AssessmentCenter' }">Assessment center</router-link>
          | <span class="title"> Major Accident</span>
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mx-auto" cols="12" md="12">
        <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
          <card-title :icon="require('@/assets/img/assesment-center/accident_icon.png')">Bid Form Report</card-title>
          <div v-if="isBidForms">
            <div class="tw-flex tw-justify-end mt-4">
              <v-tabs v-model="tab">
                <v-tab class="tw-text-lg" v-for="(item, i) in tabsArray" :key="i">
                  <span class="c-blue-text tw-text-base">{{ item }}</span>
                </v-tab>
              </v-tabs>
              <div class="tw-w-full sm:tw-w-1/3 md:tw-w-1/4 tw-pb-3 md:tw-pb-5">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                  hide-details></v-text-field>
              </div>
            </div>
            <div>
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in tabsArray" :key="item.tab">
                  <v-data-table :headers="computedHeaders" :items="computedBookings" :loading="loadingTable"
                    :page.sync="pagination.pageNumber" :items-per-page.sync="pagination.perPage"
                    :server-items-length="pagination.totalItems" :search.sync="search" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :footer-props="{
            itemsPerPageOptions: [10, 20, 30, 40, 50],
          }">
                    <template v-slot:[`item.accidentCategory`]="{ item }">
                      <v-chip v-if="item.status === 'EXT_ASSESSOR_ASSIGNED'" color="warning" small>{{
            item.accidentCategory }}</v-chip>
                      <v-chip v-else-if="item.status === 'ASSIGNED_TO_CLUSTER'" color="success" small>
                        Bid Template Sent</v-chip>
                      <v-chip v-else-if="item.status === 'BIDDING_TEMPLATE_CREATED'" color="primary" small>
                        Bid Template created</v-chip>
                      <v-chip v-else :color="item.status === 'PENDING' ? 'warning' : 'success'" small> {{ item.status |
            formatStatus }}</v-chip>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <v-chip v-if="item.status === 'GARAGE_SUPPLEMENTARY_TO_EXT_ASSESSOR'" color="warning"
                        small>Pending</v-chip>
                        <v-chip v-else-if="item.status === 'GARAGE_REINSPECTION_TICKET_RECEIVED'" color="warning"
                        small>Pending</v-chip>
                        <v-chip v-else-if="item.status === 'GARAGE_BID_REINSPECTED' ||  item.status === 'GARAGE_INVOICE_SATISFACTION_NOTE_RECEIVED'" color="success"
                        small>Re-inspected</v-chip>
                      <v-chip v-else color="success" small> Done</v-chip>
                    </template>
                    <template v-slot:[`item.createdAt`]="{ item }">
                      <span>{{ item.createdAt | formatToHuman }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <div class="tw-flex">
                        <v-btn v-if="item.status === 'EXT_ASSESSOR_ASSIGNED'" class="tw-ml-2" color="warning" small :to="{
            name: 'BidFormPreparation',
            params: { bookingId: item.id },
          }"
          >Create Report</v-btn>
                        <v-btn v-else-if="item.status === 'GARAGE_SUPPLEMENTARY_TO_EXT_ASSESSOR'"  @click="toSupplementaryForm(item)" text color="primary">
                          Analyse
                        </v-btn>
                        <v-icon medium v-else-if="item.status === 'GARAGE_SUPPLEMENTARY_TO_INT_ASSESSOR'"
                          color="success" class="px-2 py-2"> mdi-check-circle </v-icon>
                          <a v-else-if="item.status === 'GARAGE_REINSPECTION_TICKET_RECEIVED'" href="#" :key="item.action" class="c-blue-text"
                          :to="{ name: 'ReinspectionView'}" @click="toReinspectionForm(item)"
                          >
                          Re-inspect</a>
                          <a v-else-if="item.status === 'GARAGE_BID_REINSPECTED' ||  item.status === 'GARAGE_INVOICE_SATISFACTION_NOTE_RECEIVED'"  @click="toReinspectionForm(item)"><v-icon class="pr-5"
                            color="primary" small>mdi-eye</v-icon>
                          View</a>
                        <a v-else href="#" :key="item.action" @click="openDialog(item)"><v-icon class="pr-5"
                            color="primary" small>mdi-eye</v-icon>
                          View</a>
                      </div>
                    </template>
                  </v-data-table>
                </v-tab-item>
              </v-tabs-items>
            </div>
            <hr />
          </div>
          <div v-else class="
              tw-py-4
              md:tw-py-14
              tw-flex tw-items-center tw-justify-center tw-flex-col
            ">
            <img :src="require('@/assets/img/assesment-center/no-car.png')" class="tw-h-16 md:tw-h-28" alt="no car" />
            <p class="tw-w-full md:tw-w-1/3 tw-text-xs tw-p-3 tw-text-justify">
              There’s no more vehicles to categorise here, please head to the
              <span class="c-blue-text tw-font-bold">next module in the action panel</span>
              to handle the repairs of the already inspected vehicles
            </p>
            <v-btn :to="{ name: 'AssessmentCenter' }" class="tw-w-full md:tw-w-1/3" color="warning">Go To Action
              Panel</v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="1200">
      <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-4 md:tw-mt-10">
        <card-title icon="mdi-chat-processing" :is-image="false">Submitted Bid Form</card-title>
        <v-row class="tw-py-5 md:tw-py-10">
          <v-col class="mx-auto" cols="12" md="12">
            <bid-template-preview :is-garage="false" :booking="booking" />
          </v-col>
        </v-row>
        <v-card-actions class="justify-end">
          <v-btn color="error" text @click="dialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import CardTitle from '@/components/CardTitle'
import { formatToHuman } from '@/utils/time'
import { formatStatus } from '@/utils/helpers'
import BidTemplatePreview from '@/components/assessment-center/bids-submitted/bidTemplatePreview'
import bookingsMixin from '@/mixins/bookingsMixin'

export default {
  name: 'MajorAccident',
  components: { BidTemplatePreview, CardTitle },
  mixins: [bookingsMixin],
  data() {
    return {
      loadingTable: true,
      bookings: [],
      dialog: false,
      supplementaryBooking: [],
      reinspectionBookings: [],
      headers: [
        { text: 'No.', value: 'id' },
        { text: 'Customer', value: 'clientInfo.name', sortable: false },
        {
          text: 'Reg. No.',
          value: 'vehicleInfo.registrationNumber',
          sortable: false,
        },
        { text: 'Date', value: 'createdAt', sortable: false },
        { text: 'Phone No.', value: 'clientInfo.phoneNumber', sortable: false },
        { text: 'Status', value: 'accidentCategory' },
        { text: 'Action', value: 'actions', sortable: false },
      ],
      fetchBookingsUrl: '',
      fetchSupplementariesUrl: '',
      supplementaryStatus: 'GARAGE_SUPPLEMENTARY_TO_EXT_ASSESSOR',
      tabsArray: ['Bid Forms', 'Supplementaries', 'Re-inspection'],
      tab: 0,
      isSupplementary: false,
      isReinspection: false,
    }
  },
  watch: {
    tab() {
      if (this.tab === 1) {
        this.fetchSupplementaries()
        this.isSupplementary = true
        this.isReinspection = false
      } else if (this.tab === 2) {
        this.fetchReinspections()
        this.isSupplementary = false
        this.isReinspection = true
      } else {
        this.fetchBookings()
        this.isSupplementary = false
        this.isReinspection = false
      }
    },
  },
  computed: {
    isBidForms() {
      return this.bookings.length > 0 || this.supplementaryBooking.length > 0
    },
    computedBookings: function () {
      if (this.tab === 1) return this.supplementaryBooking
      else if (this.tab === 2) return this.reinspectionBookings
      else return this.bookings
    },
    computedHeaders() {
      if (this.tab === 1) return this.supplementaryHeaders
      else if (this.tab === 2) return this.reinspectionHeaders
      return this.headers
    },
  },
  mounted() {
    this.fetchBookingsUrl = `/motor-assessment/api/booking?bookingRequestType=EXTERNAL_ASSESSOR_ASSIGNED_BOOKINGS&userId=${this.$store.state.auth.user.sub}`
    this.fetchSupplementariesUrl = `/motor-assessment/api/garages/supplementary/${this.$store.state.auth.user.sub}`
    this.fetchReinspectionsUrl = `/motor-assessment/api/garages/re-inspection/${this.$store.state.auth.user.sub}`
    this.fetchBookings()
    this.sortBy = [false, false, false, false]
  },
  methods: {
    openDialog(booking) {
      this.booking = {
        id: booking.id,
        biddingForm: booking.biddingTemplate,
        ...{
          clientInfo: booking.clientInfo,
          vehicleInfo: booking.vehicleInfo,
          policyInfo: booking.policyInfo,
        },
        externalAssessor: booking.externalAssessor,
      }
      this.dialog = true
    },
    toSupplementaryForm(bid) {
      this.$store.dispatch('bidForm/updateSupplementary', bid)
      this.$router.push({ name: 'SupplementaryReview' })
    },
    toReinspectionForm(bid) {
      this.$router.push({ name: 'ReinspectionView',
      params: { bookingId: bid.bookingId }
    })
    }
  },
  filters: {
    formatToHuman,
    formatStatus,
  },
}
</script>
